export enum NVXRole {
  ADMIN = 'admin',
  FE_ADDRESSBOOKADMINREAD = 'FE_ADDRESSBOOKADMINREAD',
  FE_ADDRESSBOOKORGANIZATIONS = 'FE_ADDRESSBOOKORGANIZATIONS',
  FE_ADDRESSBOOKPEOPLEREAD = 'FE_ADDRESSBOOKPEOPLEREAD',
  FE_ADDRESSBOOKREAD = 'FE_ADDRESSBOOKREAD',
  FE_BILLINGCHARGESREAD = 'FE_BILLINGCHARGESREAD',
  FE_BILLINGCONTRACTSREAD = 'FE_BILLINGCONTRACTSREAD',
  FE_BILLINGCREDITLINEREAD = 'FE_BILLINGCREDITLINEREAD',
  FE_BILLINGDISPUTESREAD = 'FE_BILLINGDISPUTESREAD',
  FE_BILLINGRATESREAD = 'FE_BILLINGRATESREAD',
  FE_BILLINGREAD = 'FE_BILLINGREAD',
  FE_DEFAULT = 'FE_DEFAULT',
  FE_DOCUMENTREAD = 'FE_DOCUMENTREAD',
  FE_FAQREAD = 'FE_FAQREAD',
  FE_NEWSHIPMENTREAD = 'FE_NEWSHIPMENTREAD',
  FE_NOTIFICATIONREAD = 'FE_NOTIFICATIONREAD',
  FE_ORGANIZATIONBILLINGREAD = 'FE_ORGANIZATIONBILLINGREAD',
  FE_ORGANIZATIONCREATE = 'FE_ORGANIZATIONCREATE',
  FE_ORGANIZATIONEDIT = 'FE_ORGANIZATIONEDIT',
  FE_ORGANIZATIONPROFILEREAD = 'FE_ORGANIZATIONPROFILEREAD',
  FE_ORGANIZATIONROLESREAD = 'FE_ORGANIZATIONROLESREAD',
  FE_PORTCASTADMIN = 'FE_PORTCASTADMIN',
  FE_QUOTATIONREAD = 'FE_QUOTATIONREAD',
  FE_RELEASEREAD = 'FE_RELEASEREAD',
  FE_SHIPMENTAIRREAD = 'FE_SHIPMENTAIRREAD',
  FE_SHIPMENTCONTAINERREAD = 'FE_SHIPMENTCONTAINERREAD',
  FE_SHIPMENTCREATE = 'FE_SHIPMENTCREATE',
  FE_SHIPMENTINBOUNDREAD = 'FE_SHIPMENTINBOUNDREAD',
  FE_SHIPMENTOCEANREAD = 'FE_SHIPMENTOCEANREAD',
  FE_SHIPMENTOUTBOUNDREAD = 'FE_SHIPMENTOUTBOUNDREAD',
  FE_SHIPMENTROADREAD = 'FE_SHIPMENTROADREAD',
  FE_SMARTTASKREAD = 'FE_SMARTTASKREAD',
  FE_STOCKLOCATION = 'FE_STOCKLOCATION',
  FE_STOCKOVERVIEWREAD = 'FE_STOCKOVERVIEWREAD',
  FE_STOCKSKUSREAD = 'FE_STOCKSKUSREAD',
  FE_STOCKSTATISTICSREAD = 'FE_STOCKSTATISTICSREAD',
  FE_STOCKWAREHOUSE = 'FE_STOCKWAREHOUSE',
  FE_USERINVITENEW = 'FE_USERINVITENEW',
  FE_USERSREAD = 'FE_USERSREAD',
}
